import pickBy from 'lodash/pickBy';
import { listPublicPlans } from '@wix/ambassador-pricing-plans-v2-plan/http';
import { ComponentRef, EditorSDK, PresetValue, SimpleSize } from '@wix/platform-editor-sdk';
import { EditorScriptFlowAPI, IHttpClient } from '@wix/yoshi-flow-editor';
import { getPanelUrl } from '@wix/yoshi-flow-editor/utils';
import SinglePlanWidget from '../components/SinglePlanWidget/.component.json';
import { PRICING_PLANS_APP_DEF_ID, SINGLE_PLAN_HELP_ARTICLE_ID } from '../constants';
import { PlanListWidgetRole, SinglePlanWidgetRole } from '../constants/elements';
import { uncompressUuidArray } from '../services/uuid-compression';
import { toError } from './errors';

const WIDGET_REF_TYPE = 'platform.components.AppWidget';
const CONTAINER_REF_TYPE = 'wysiwyg.viewer.components.RefComponent';

export async function getContainerRef(editorSDK: EditorSDK, componentRef: ComponentRef): Promise<ComponentRef> {
  const [parentRef] = await editorSDK.document.components.getAncestors('', { componentRef })!;
  return parentRef;
}

export async function getCurrentPresetId(
  editorSDK: EditorSDK,
  componentRef: ComponentRef,
): Promise<string | undefined> {
  const parentRef = await getContainerRef(editorSDK, componentRef);
  const preset = (await editorSDK.document.application.appStudioWidgets.getPreset('', {
    componentRef: parentRef,
  })) as PresetValue | null;
  return preset?.layout ?? undefined;
}

export async function getRootWidget(editorSDK: EditorSDK, componentRef: ComponentRef): Promise<ComponentRef> {
  const ancestors = await editorSDK.components.getAncestors('', { componentRef });
  const ancestorTypes = await Promise.all(
    ancestors.map((ref) => editorSDK.components.getType('', { componentRef: ref })),
  );
  /*
    There might be more than one AppWidget type component
    in the hierarchy. `getAncestors` returns ancestors in order
    from closest to furthest, so `lastIndexOf` will find the root widget
  */
  const widgetIndex = ancestorTypes.lastIndexOf(WIDGET_REF_TYPE);
  return ancestors[widgetIndex];
}

export async function getPlanWidget(editorSDK: EditorSDK, rootWidgetContainer: ComponentRef) {
  const [controllerRef] = await editorSDK.components.getChildren('', { componentRef: rootWidgetContainer });
  const [planComponent] = await editorSDK.components.findAllByRole('', {
    controllerRef,
    role: SinglePlanWidgetRole.PlanWidget,
  });
  return planComponent;
}

export async function doesPlanExist(httpClient: IHttpClient, currentPlanId?: string): Promise<Boolean> {
  if (!currentPlanId) {
    return false;
  }
  const { data } = await httpClient.request(listPublicPlans({ planIds: [currentPlanId] }));
  return Boolean(data.plans?.length);
}

export async function setFirstPlanToWidget(params: {
  widgetRef: ComponentRef;
  httpClient: IHttpClient;
  editorSDK: EditorSDK;
}): Promise<void> {
  const { widgetRef, httpClient, editorSDK } = params;
  const { data } = await httpClient.request(listPublicPlans({ limit: 1 }));
  const [newPlan] = data.plans ?? [];

  if (newPlan) {
    await editorSDK.document.application.appStudioWidgets.props.set('', {
      widgetRef,
      newProps: { planId: newPlan.id },
    });
  }
}

export async function isSinglePlanWidgetRef(editorSDK: EditorSDK, refComponent: ComponentRef) {
  const data = (await editorSDK.components.data.get('', { componentRef: refComponent })) as
    | { widgetId: string; appDefinitionId: string }
    | undefined;
  return Boolean(data?.appDefinitionId === PRICING_PLANS_APP_DEF_ID && data?.widgetId === SinglePlanWidget.id);
}

export async function assertSinglePlanWidgetExists(editorSDK: EditorSDK, refComponent: ComponentRef) {
  const widget = await getPlanWidget(editorSDK, refComponent);
  if (!widget) {
    throw new Error('SinglePlanWidget does not exist');
  }
}

export async function getPlanIdFromPlanList(params: { editorSDK: EditorSDK; componentRef: ComponentRef }) {
  const { editorSDK, componentRef } = params;
  const rootWidget = await getRootWidget(editorSDK, componentRef);
  const repeater = await findComponentByRole({
    editorSDK,
    controllerRef: rootWidget,
    role: PlanListWidgetRole.PlanList,
  });

  if (!repeater) {
    return null;
  }

  const planComponents = await editorSDK.components.getChildren('', { componentRef: repeater });
  const ancestors = await editorSDK.components.getAncestors('', { componentRef });
  const planIndex = planComponents.findIndex((plan) => ancestors.some((ancestor) => plan.id === ancestor.id));

  if (planIndex < 0) {
    return null;
  }

  const { planIds } = await editorSDK.document.application.appStudioWidgets.props.get('', { widgetRef: rootWidget });

  return uncompressUuidArray((planIds as string).split(','))[planIndex];
}

export async function openPlanForm(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  const rootWidget = await getRootWidget(editorSDK, componentRef);
  const { planId, isPlanList } = await editorSDK.application.appStudioWidgets.props.get('', {
    widgetRef: rootWidget,
  });

  const id = isPlanList ? await getPlanIdFromPlanList({ editorSDK, componentRef }) : planId;
  const pricingPlansUrl = id ? `pricing-plans/edit/${id}` : 'pricing-plans/new';
  await editorSDK.editor.openDashboardPanel('', { url: pricingPlansUrl, closeOtherPanels: true });
  try {
    const isCurrentPlanValid = await doesPlanExist(flowAPI.httpClient, id as string | undefined);
    if (!isCurrentPlanValid) {
      await setFirstPlanToWidget({
        widgetRef: rootWidget,
        httpClient: flowAPI.httpClient,
        editorSDK,
      });
    }
  } catch (e) {
    flowAPI.errorMonitor.captureException(toError(e));
  }
  await editorSDK.application.livePreview.refresh('', {
    shouldFetchData: true,
    source: 'PLAN_FORM_CLOSED',
  });
}

export async function componentHasRole(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  role: SinglePlanWidgetRole;
}): Promise<boolean> {
  const { editorSDK, componentRef, role } = params;
  const [connection] = await editorSDK.document.controllers.listConnections('', { componentRef });
  return connection?.role === role;
}

export async function getParentWidget(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
}): Promise<ComponentRef | null> {
  const { editorSDK, componentRef } = params;
  const ancestors = await editorSDK.document.components.getAncestors('', { componentRef });
  for (const ancestorRef of ancestors) {
    const type = await editorSDK.document.components.getType('', { componentRef: ancestorRef });
    if (type === WIDGET_REF_TYPE) {
      return ancestorRef;
    }
  }
  return null;
}

export async function findComponentByRole(params: {
  editorSDK: EditorSDK;
  controllerRef: ComponentRef;
  role: SinglePlanWidgetRole | PlanListWidgetRole;
}): Promise<ComponentRef | null> {
  const { editorSDK, controllerRef, role } = params;
  const [component] = await editorSDK.document.components.findAllByRole('', { controllerRef, role });
  return component ?? null;
}

export async function isWidgetRef(editorSDK: EditorSDK, componentRef: ComponentRef) {
  const type = await editorSDK.document.components.getType('', { componentRef });
  return type === WIDGET_REF_TYPE;
}

export async function isContainerRef(editorSDK: EditorSDK, componentRef: ComponentRef) {
  const type = await editorSDK.document.components.getType('', { componentRef });
  return type === CONTAINER_REF_TYPE;
}

export async function updateWidgetSizeClassic(params: {
  editorSDK: EditorSDK;
  containerRef: ComponentRef;
  width?: number;
  height?: number;
}) {
  const { editorSDK, containerRef, width, height } = params;
  const newSize = pickBy(
    {
      width,
      height,
    },
    (val) => val !== undefined,
  );
  return editorSDK.document.components.layout.update('', {
    componentRef: containerRef,
    layout: newSize,
  });
}

export async function updateWidgetSizeEditorX(params: {
  editorSDK: EditorSDK;
  containerRef: ComponentRef;
  width?: SimpleSize;
  height?: SimpleSize;
}) {
  const { editorSDK, containerRef, width, height } = params;
  const currentLayout = await editorSDK.document.responsiveLayout.get('', { componentRef: containerRef });
  const newSize = pickBy({ width, height }, (val) => val !== undefined);
  return editorSDK.document.responsiveLayout.update('', {
    componentRef: containerRef,
    responsiveLayout: {
      ...currentLayout,
      componentLayouts: [
        {
          ...currentLayout.componentLayouts[0],
          ...newSize,
        },
        ...currentLayout.componentLayouts.slice(1),
      ],
    },
  });
}

export async function openChangeRibbonTextPanel(params: {
  editorSDK: EditorSDK;
  componentRef: ComponentRef;
  flowAPI: EditorScriptFlowAPI;
}) {
  const { editorSDK, componentRef, flowAPI } = params;
  return editorSDK.editor.openComponentPanel(
    '',
    {
      title: flowAPI.translations.t('blocks.ribbon.change-text.title'),
      url: getPanelUrl('Ribbon', 'ChangeTextPanel'),
      height: 100,
      width: 288,
      componentRef,
      initialData: {
        componentRef,
      },
      helpId: SINGLE_PLAN_HELP_ARTICLE_ID,
    },
    (token) => {
      editorSDK.editor.showPanelPreloader(token);
    },
  );
}

export function getWidgetProps<T extends WidgetProps>(editorSDK: EditorSDK, widgetRef: ComponentRef): Promise<T> {
  return editorSDK.document.application.appStudioWidgets.props.get('', { widgetRef }) as Promise<T>;
}

export function setWidgetProps<T extends WidgetProps>(params: {
  editorSDK: EditorSDK;
  widgetRef: ComponentRef;
  newProps: Partial<T>;
}): Promise<void> {
  const { editorSDK, widgetRef, newProps } = params;
  const definedProps = pickBy(newProps, (value) => value !== undefined) as WidgetProps;
  return editorSDK.document.application.appStudioWidgets.props.set('', { widgetRef, newProps: definedProps });
}

export async function getPlanVariantRefsFromPlanList(params: {
  editorSDK: EditorSDK;
  planListWidgetRef: ComponentRef;
}) {
  const { editorSDK, planListWidgetRef } = params;

  const planRoles = [
    PlanListWidgetRole.PlanVariantCustomWidget,
    PlanListWidgetRole.PlanVariantDefaultWidget,
    PlanListWidgetRole.PlanVariantHighlightedWidget,
  ];

  return Promise.all(
    planRoles.map(async (role) => {
      const [ref] = await editorSDK.document.components.findAllByRole('', { controllerRef: planListWidgetRef, role });
      return ref;
    }),
  );
}
